<template>
    <div class="container">
      <content-title :nav="nav"></content-title>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="手机号">
            <el-input v-model="formInline.region"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="status" clearable>
              <el-option label="已支付" :value="2"></el-option>
              <el-option label="待支付" :value="0"></el-option>
              <el-option label="已取消" :value="8"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="type" clearable>
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="contentList">
        <el-table
          v-loading="is_loading"
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="list"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 20px"
          highlight-current-row
        >
          <el-table-column
            prop="flowing_no"
            align="center"
            label="订单号"
          ></el-table-column>
          <el-table-column
            align="center"
            label="商品名称"
          >
          <template slot-scope="{row}">
              <p v-if="row.rcharge_type === 'consume'" 
                >消费金</p
              >
              <p v-else-if="row.rcharge_type === 'coupon'" 
                >优惠券</p
              >
              <p v-else-if="row.rcharge_type === 'member'" 
                >会员卡</p
              >
              <p v-else 
                >{{ row.rcharge_type  }}</p
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="map.userinfo.nick_name"
            align="center"
            label="用户昵称"
          ></el-table-column>
          <el-table-column
            prop="amount"
            align="center"
            label="付款金额"
          ></el-table-column>
          <el-table-column
            prop="pay_type"
            align="center"
            label="付款方式"
            :formatter="Paytype"
          ></el-table-column>
          <el-table-column
            prop="source"
            align="center"
            label="订单渠道"
            :formatter="Paysource"
          ></el-table-column>
          <el-table-column
            prop="finished_time"
            align="center"
            label="下单时间"
            width="200px"
          ></el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 2" style="color: #67c23a"
                >已付款</span
              >
              <span v-if="scope.row.status == 4" style="color: #f56c6c"
                >退款</span
              >
              <span v-if="scope.row.status == 1" style="color: #409eff"
                >待付款</span
              >
              <span v-if="scope.row.status == 0" style="color: #409eff"
                >待付款</span
              >
              <span v-if="scope.row.status == 8" style="color: #409eff"
                >已取消</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="total"
      ></paging-fy>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        nav: { firstNav: "抖音活动", secondNav: "抖音订单数据" },
        list: [],
        is_loading: true,
        finshed: true,
        dialogVisible: false,
        total: 0,
        timeDate: [],
        formInline: {
          user: "",
          region: "",
        },
        type:'',
        typeList: [
          {
            value: 'member',
            label: "会员卡",
          },
          {
            value: 'coupon',
            label: "天赋课程券",
          },
          {
            value: 'consume',
            label: "消费金",
          }
        ],
        pic: "",
        status: "",
        source:'',
        currentPage: 1,
      };
    },
  
    mounted() {
      this.getRefoundList();
    },
  
    methods: {
      Paytype(row, column) {
        let type = row.pay_type;
        if (type == "ali") {
          return "支付宝";
        } else if (type == "wx") {
          return "微信";
        } else if (type == "point") {
          return "权益点";
        } else if (type == "dy") {
          return "抖音";
        } else {
          return type;
        }
      },
      Paysource(row) {
        let source = row.source;
        let pay = row.pay_type;
        if (source == "sport" && pay == "dy") {
          return "抖音小程序";
        } else if (source == "sport" && pay != "dy") {
          return "微信小程序";
        } else {
          return source;
        }
      },
      search(){
          this.currentPage = 1
          this.list = []
          this.getRefoundList()
      },
      changeCurrentPage(v){
          this.currentPage = v
          this.getRefoundList()
      },
      //查询订单
      getRefoundList() {
        this.is_loading = true;
        let url = "/user/rechargerecord/queryByMessage";
        let params = {
          pageSize: 10,
          currentPage: this.currentPage,
          source : 'sport',
          pay_type : 'dy'
        };
        if (this.type != "") {
          params.rcharge_type = this.type;
        }
        if(this.status !== ""){
            params.status = this.status
        }
        if(this.$route.query.time){
            let time = this.$route.query.time
            params.start_time = time+':00:00'
          params.end_time = time+':59:59'
        }
        if (this.formInline.region) {
          params.login_name = this.formInline.region; //手机号
        }
        this.$axios
          .get(url, {
            params,
          })
          .then((res) => {
            this.total = res.data.data.total;
            this.list = res.data.data.rows;
            this.is_loading = false;
          });
      },
      getpic(v) {
        this.pic = v;
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  </style>